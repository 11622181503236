import React, { useContext, useEffect } from "react"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import SVG from 'react-inlinesvg';

import Layout from "../components/wrappers/layout";
import SiteHeaderAlt from "../components/wrappers/site-header-alt";
import SEO from "../components/seo";
import Header from '../components/header';
import CardArticle from '../components/card-article';
import { graphql } from 'gatsby';
import { stripString } from "../utils";
import Markdown from "markdown-to-jsx"
import Cta from "../components/cta";
import Logos from "../components/logos";
import TabbedServices from "../components/tabbed-services";
import Link from "../components/link";
import Testimonial from "../components/testimonial";
import Carousel from "../components/wrappers/carousel";
import CaseListCarousel from "../components/case-list-carousel";
import CaseListCarouselMobile from "../components/case-list-carousel-mobile";
import CaseList from "../components/case-list";
import Media from "../components/media";
import { CustomHr } from "../components/CustomHr";
import TriangleBubbles from "../components/hashTagsTriangles";
import Faq from "../components/faq";

const ServiceDetailUx = props => {
  const { general, footers, headers, serviceUx, servicesPage, theme } = props.data.strapi;
  const { switchToDarkMode } = theme;
  
  const { serviceDetailFooterBgColorDarkMode, serviceDetailFooterBgColorLightMode, generalBackgroundColorDarkMode, generalBackgroundColorLightMode, clientLogosColorDarkMode, clientLogosColorLightMode , fontColorDarkMode, fontColorLightMode, hashtagsBackgroundColor, hashtagsBackgroundColorDarkMode, pageTransitionBgColorLightMode, pageTransitionBgColorDarkMode } = general; 
  const language = props.pageContext.language;
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 
  const openingHours = (language === "en") && servicesPage?.openingHours || ""; 
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const darkMode = state.darkModePreference === 'moon';
  const breakpoints = useBreakpoint();
  const imageBorderRadiusForServices  = general?.imageBorderRadiusForServices;
  const { darkTheme, hero, articles, selectCasesOrServices, services, services2, affix, cta1, cta2, testimonials, copyright, seo, author, serviceEmail, service_ux, mobileHeroBackground, clientlogos, logosHeading, faqs, reasons } = serviceUx;    
  const casesAndServices = serviceUx?.casesAndServices;
  const pageRedirectInfo = service_ux; 

  const redirectTo = pageRedirectInfo?.language && pageRedirectInfo?.slug ? `/${pageRedirectInfo?.language}/services/${pageRedirectInfo?.slug}` : ""; 
  const servicesfooterColor = darkMode ? serviceDetailFooterBgColorDarkMode : serviceDetailFooterBgColorLightMode
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const logosBgColor = darkMode ? clientLogosColorDarkMode : clientLogosColorLightMode; 
  const displayedHashtagBgColor = darkMode ? hashtagsBackgroundColorDarkMode : hashtagsBackgroundColor;
  const pageTransitionBgColor = darkMode ? pageTransitionBgColorDarkMode : pageTransitionBgColorLightMode; 
  const { hashtagsSection } = serviceUx;

  const caseStudies = selectCasesOrServices  && selectCasesOrServices.map((item,index) => {
    if (item.__typename) {
      const itemKey = Object.keys(item)[1]; 
      const isServices = itemKey !== 'case_study';

      return {
        ...item[itemKey], 
        language: language, 
        id:index,
        title: item && item[itemKey] && (item[itemKey]?.title ||  item[itemKey]?.hero?.header?.heading || item[itemKey]?.intro?.header?.heading || ''),
        isServices
      }
    } 
    else {
      return null 
    }
  });
  // getting  page first H1 to set as meta title and description if not set in strapi .
  const pageFirstHeading = hero && hero.header && (hero.header.supheading + " "+ hero.header.heading);
  const primaryBtn = general?.primaryBtn;

  const seoTitle = seo && seo.metaData.metaTitle || pageFirstHeading|| "service detail design ";
  const seoDescription = seo && seo.metaData.metaDescription || pageFirstHeading || "service detail design";
  const seoKeywords = seo && seo.metaData?.keywords;
  const isSubOpen = state.siteSubNav;
  const greySectionLightModeBgColor = general?.greySectionLightModeBgColor; 
  const greySectionDarkModeBgColor = general?.greySectionDarkModeBgColor; 
  const greySectionBgColor = darkMode ? greySectionDarkModeBgColor : greySectionLightModeBgColor; 
  const isMobileView = breakpoints.sm;
  const background = isMobileView && mobileHeroBackground ? mobileHeroBackground :  hero?.background && hero?.background;
  const [backgroundImage, setBackgroundImage] = React.useState("");
  const [anchorLinks, setAnchorLinks] = React.useState([])
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  const filteredRelatedCards = articles?.relatedCard.filter(relatedCard => !relatedCard?.blog?.inactive || relatedCard?.case_study); 

  footer.theme = 'short';
  if (copyright) {
     footer.copyright = copyright;
  }


  const newAnchorLinks = []; 

  function getMeRandomCaseStudies (sourceArray, neededElements) {
    let items = sourceArray;
    let newItems = [];

    for (var i = 0; i < neededElements; i++) {
      var idx = Math.floor(Math.random() * items.length);
      newItems.push(items[idx]);
      items.splice(idx, 1);
    }

    return newItems;
  }
  
  useEffect(() => {
    hero?.header?.anchorText && newAnchorLinks.push({header: hero.header.anchorText  , display: true}); 
    services?.header.anchorText && newAnchorLinks.push({header: services.header.anchorText, display: true}); 
    services2?.header.anchorText && newAnchorLinks.push({header: services2.header.anchorText, display: true});
    cta1?.text?.anchorText && newAnchorLinks.push({header: cta1.text.anchorText, display: true}); 
    testimonials?.anchorText && newAnchorLinks.push({header: testimonials.anchorText, display: true}); 
    articles?.anchorText && newAnchorLinks.push({header: articles.anchorText, display: true}); 
    cta2?.anchorText && newAnchorLinks.push({header: cta2.anchorText, display: true}); 
    setAnchorLinks(newAnchorLinks);
    const id = "services"; 
    if (!isSubOpen[id]) {
      dispatch({ 
        type: "TOGGLE_SITE_SUB_NAV" , 
        payload: {
          id: id
        } 
      });
    } 
  }, []); 

  useEffect(()=> {
   if (isMobileView) {
    setBackgroundImage(background);
   } else {
    setBackgroundImage(background);
   }
  }, [background]);

  const imageExts = ['.svg', '.png', '.gif', '.jpg', '.jpeg'];
  const isImage = backgroundImage && imageExts.includes(backgroundImage.ext);

  return (
    <Layout header={header} footer={footer} language={language} serviceEmail={serviceEmail} showWhiteIcon={true} redirectTo={redirectTo} anchorLinks={anchorLinks} isImage={!isImage} servicesfooterColor={servicesfooterColor} isServicesLandingPage={true} logosBgColor={logosBgColor}>
      <SEO
        websiteFontColor={websiteFontColor} 
        template="service-detail" 
        title={seoTitle} 
        description={seoDescription} 
        keywords={seoKeywords} 
        darkTheme={darkTheme}  
        darkMode={darkMode}
        websiteBackgroundColor={sectionBackgroundColor}
      />

      {hero && (
        <section className="hero hero--white service-detail-design" style={{backgroundColor: hero.backgroundColor}} id={stripString(hero.header.anchorText ) || ''}>
          <div className="container-lg container align-items-end">
            <div className="hero__background hero__background--shadow">
              {backgroundImage && (
                <div className="hero__background-inner">
                  <Media media={backgroundImage} showVideoAutoWith={true} />
                </div>
              )}
            </div>
            <div className="hero__main">
              <div className="row align-items-end">
                <div className="col-12">
                  <div className="hero__content">
                    <header className="hero__header header">
                      <h1 className="header__heading">
                        <small className="header__preheading">
                          {hero.header.supheading}
                        </small>
                        {hero.header.heading}
                      </h1>
                    </header>
                    
                    <div className="hero__body rich-text">
                       <Markdown options={{
                          overrides: {a: {
                            component: Link
                          },                           
                          hr: { 
                            component: CustomHr,
                              props: {
                                borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                              }
                            }  
                          }
                        }}>
                        {hero.body}
                      </Markdown>
                    </div>

                    {hero.links && (
                      <div className="hero__buttons buttons">
                        {hero.links.map((link) => {
                          return (
                            <>
                            {link.document && (
                              <a href={`${document.document && document.document.url}`} className="button button--white" target="_blank">
                                <span className="button__text">{document.label}</span>
                              </a>
                            )}
                            {link.path && (
                              <Link
                                className="button button--white"
                                to={`${link.path}`}
                              >
                                <span className="button__text">{link.label}</span>
                              </Link>                      
                            )}
                            </>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <SiteHeaderAlt header={header} serviceEmail={serviceEmail} redirectTo={redirectTo}  anchorLinks={anchorLinks} servicesfooterColor={servicesfooterColor}>
        {services && (
          <TabbedServices 
            component={services} 
            language={language} 
            shouldNotLazyLoad={true} 
            imageBorderRadiusForServices={imageBorderRadiusForServices}
            sectionBackgroundColor={sectionBackgroundColor}
            websiteFontColor={websiteFontColor}
            isIndustriesPage={true}
          />
        )}
        {reasons && (
          <section className="section" style={{backgroundColor: sectionBackgroundColor}}>
            {reasons.header && (
              <Header heading={reasons.header.heading}  />
            )}

            <div className="container">
              <div className="row">
                <div className="col-tw-12 col-lg-8 offset-lg-2 rich-text">
                  <ul>
                    {reasons.reasons.map((reason, index) => {
                      const isOdd = index % 2;

                      if (isOdd) { return; }

                      return (
                        <li>                  
                          <Markdown options={{
                            overrides: {a: {
                              component: Link
                            },                           
                            hr: { 
                                component: CustomHr,
                                props: {
                                  borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                }
                              }  
                            }
                          }}>
                            {reason.richText}
                          </Markdown>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className="col-tw-12 col-lg-8 offset-lg-2 rich-text">
                  <ul>
                    {reasons.reasons.map((reason, index) => {
                      const isOdd = index % 2;

                      if (!isOdd) { return; }

                      return (<li>                  
                        <Markdown options={{
                          overrides: {a: {
                            component: Link
                          },                           
                          hr: { 
                              component: CustomHr,
                              props: {
                                borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                              }
                            }  
                          }
                        }}>
                          {reason.richText}
                        </Markdown>
                        </li>
                        )
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        )}
        {(casesAndServices && caseStudies && caseStudies.length > 0 ) && (
          <section className="section" style={{backgroundColor: sectionBackgroundColor}}>
            {casesAndServices.heading && (
              <div className="container">
                <div className="row">
                  <div className="offset-lg-1 col-md-6 col-lg-6 random-heading">
                    {casesAndServices.heading && (
                      <header className="header">
                        <h1 className="header__heading header__heading--space">
                          {casesAndServices.sup && (
                            <small className="header__preheading">
                              {casesAndServices.sup}
                            </small>
                          )}
                          {casesAndServices && casesAndServices.heading}
                        </h1>
                      </header>
                    )}
                  </div>
                </div>
              </div>
            )}
            {caseStudies && caseStudies.length > 0 && (
              <div className="container">
                <div className="row">
                  <div className="offset-lg-1 col-lg-10">
                    <CaseList 
                      caseStudies={caseStudies} 
                      oddElementMarginTop={true} 
                      imageBorderRadiusForServices={imageBorderRadiusForServices}
                      hashtagsBackgroundColor={displayedHashtagBgColor}                      
                      websiteFontColor={websiteFontColor}
                      showCaseIntroText={true}
                      pageTransitionBgColor={pageTransitionBgColor}
                      isIndustriesPage={true}
                      showLandingPreview={true}
                    />
                  </div>
                </div>
              </div>
            )}
          </section>
        )}
        {cta1 && (
          <Cta 
            component={cta1} 
            className="cta--wide" 
            showNumber={true} 
            showTeaser={true} 
            author={author}
            openingHour={openingHours}  
            anchorId={cta1?.text?.anchorText ? cta1.text.anchorText : ''}
            shouldNotLazyload={true}
            greySectionBgColor={greySectionBgColor}
            fontColorLightMode={websiteFontColor}
          />
        )}
        {services2 && (
          <TabbedServices 
            component={services2} 
            language={language} 
            shouldNotLazyLoad={true} 
            imageBorderRadiusForServices={imageBorderRadiusForServices}
            sectionBackgroundColor={sectionBackgroundColor}
            websiteFontColor={websiteFontColor}
            isIndustriesPage={true}
          />
        )} 
        {(testimonials && testimonials?.showTestimonials && testimonials?.testimonials?.length ) ?  (
          <section className="section" id={stripString(testimonials.anchorText)} style={{backgroundColor: sectionBackgroundColor}}>
            <div className="container">
              <Testimonial 
                testimonialBtnText={primaryBtn}
                testimonials={testimonials.testimonials} 
                shouldNotLazyLoad={true} 
                title={testimonials.title}
                imageBorderRadiusForServices={imageBorderRadiusForServices}
                logosBgColor={logosBgColor}
                sectionBackgroundColor={sectionBackgroundColor}
                isIndustriesPage={true}
              />
            </div>
          </section>
        ) : (<></>)}

        {clientlogos.length && (
          <Logos 
            className={"section--grey"}
            component={{
              header: { 
                heading: logosHeading
              }, 
              logos: clientlogos.map((clientLogo) => clientLogo.logo)
            }} 
            sectionBackgroundColor={sectionBackgroundColor} 
            logosBgColor={logosBgColor} 
            isIndustriesPage={true}
          />

        )}
        {(faqs && faqs?.faqs.length > 0) && (
            <section className="section faqs section--grey" style={{backgroundColor: greySectionBgColor}}>
              {faqs.header && (
                <Header 
                  heading={faqs.header.heading} 
                  supheading={faqs.header.supheading} 
                  isIndustriesPage={true}
                />
              )}

              {faqs.faqs && (
                <div className="container">
                  <div className="row">
                    {faqs.faqs.map((faq) => {
                      return (
                        <div className="col-tw-12 col-md-9 offset-md-1 col-lg-8 offset-lg-2">
                          <Faq heading={faq.heading} showWhitePlus={darkTheme || switchToDarkMode || darkMode} fontColorLightMode={fontColorLightMode}>
                            <Markdown options={{
                              overrides: {a: {
                                component: Link
                              },                           
                              hr: { 
                                  component: CustomHr,
                                  props: {
                                    borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                  }
                                }  
                              }
                            }}>
                              {faq.richText || ''}
                            </Markdown>
                          </Faq>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </section>
          )}
        {(articles && filteredRelatedCards.length > 0 ) && (
          <section className="cards-articles section" id={articles?.anchorText ? stripString(articles.anchorText) : ''}>
            <Header 
              heading={articles.heading} 
              supheading={articles.sup} 
              withFullWidth={true}
              isIndustriesPageM={true}
            />

            <div className="container">
              <div className="row card-articles">
                {filteredRelatedCards.map((relatedCard, index) => {
                  const article = relatedCard.blog || relatedCard.case_study;
                  return (
                    <CardArticle 
                      article={article} 
                      path={relatedCard.blog ? 'blog' : 'case-studies'} 
                      shouldNotLazyLoad={true} 
                      imageBorderRadiusForServices={imageBorderRadiusForServices} 
                      hashtagsBackgroundColor={displayedHashtagBgColor}
                      sectionBackgroundColor={sectionBackgroundColor}
                    />
                  )
                })}
              </div>
            </div>
          </section>
        )}
        {hashtagsSection && (
          <section className="section">
            <TriangleBubbles  
              hashtagsBackgroundColor={displayedHashtagBgColor} 
              borderRadius={imageBorderRadiusForServices}
              hashtagsSection={hashtagsSection}
              isIndustriesPage={true}
              centerHeading={true}
            />
          </section>
        )}
        {cta2 && (
          <Cta 
            component={cta2} 
            className="cta--wide" 
            showNumber={true} 
            showTeaser={true} 
            author={author} 
            openingHour={openingHours} 
            anchorId={cta2?.anchorText ? cta2.anchorText : ''}
            shouldNotLazyload={true}
            greySectionBgColor={greySectionBgColor}
            fontColorLightMode={websiteFontColor}
          />
        )}
      </SiteHeaderAlt>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ServiceDetailUx($id: ID!, $language: String!) {
    strapi {
      general {
        primaryBtn
        blogsOverviewHeadline
        imageBorderRadiusForServices
        greySectionLightModeBgColor
        greySectionDarkModeBgColor
        serviceDetailFooterBgColorDarkMode
        serviceDetailFooterBgColorLightMode
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        clientLogosColorDarkMode
        clientLogosColorLightMode
        fontColorDarkMode
        fontColorLightMode
        hashtagsBackgroundColor
        hashtagsBackgroundColorDarkMode
        pageTransitionBgColorLightMode
        pageTransitionBgColorDarkMode
      }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
      theme {
        switchToDarkMode
      }
      servicesPage {
        openingHours
      }
      serviceUx(id: $id) {
        darkTheme
        affix
        author {
          name
          picture {
            url
          }
          jobTitle
        }
        faqs {
          header {
            heading
            supheading
          }
          faqs {
            heading
            richText
          }
        }
        hashtagsSection {
          heading
          tags {
            tag
          }
        }
        logosHeading
        clientlogos {
          logo {
            url
            ext
            mime
            alternativeText
          }
        }  
        service_ux {
          language
          slug
        }
        reasons {
          header {
            supheading
            heading
          }
          reasons {
            richText
          }
        }
        mobileHeroBackground {
          url
          alternativeText
          mime
          caption
          ext
        }
        hero {
          background {
            url
            alternativeText
            mime
            caption
            ext
          }
          header {
            supheading
            heading
            anchorText
          }
          body
          links {
            label
            path
            document {
              url
            }
          }
        }
        selectCasesOrServices {
          ... on STRAPI_ComponentPlainServiceCaseyNewComponent {
            service_casey_new {
              slug
              previewImage {
                url
                ext
                alternativeText
                caption
                mime
              }
              hero {
                header {
                  heading
                }
              }
            }
          }
          ... on STRAPI_ComponentPlainServiceDesignComponent {
            service_design {
              slug
              previewImage {
                url
                ext
                alternativeText
                caption
                mime
              }
              hero {
                header {
                  heading
                }
              }
            }
          }
          ... on STRAPI_ComponentPlainServiceCaseyComponent {
            service_casey {
              slug
              previewImage {
                url
                ext
                alternativeText
                caption
                mime
              }
              hero {
                header {
                  heading
                }
              }
            }
          }
          ... on STRAPI_ComponentPlainServiceTecnicalComponent {
            service_technical {
              slug
              previewImage {
                url
                ext
                alternativeText
                caption
                mime
              }
              hero {
                header {
                  heading
                }
              }
            }
          }
          ... on STRAPI_ComponentPlainServiceUxComponent {
            service_ux {
              slug
              previewImage {
                url
                ext
                alternativeText
                caption
                mime
              }
              hero {
                header {
                  heading
                }
              }
            }
          }
          ... on STRAPI_ComponentPlainCasesComponent {
            case_study {
              ...CardArticleCaseStudy
            }
          }
        }
        services {
          header {
            supheading
            heading
            anchorText
          }
          services {
            heading
            media {
              media {
                url 
              }
            }
            body {
              richText
            }
            link {
              label
              path
            }
          }
        }
        services2 {
          header {
            supheading
            heading
            anchorText
          }
          services {
            heading
            media {
              media {
                url 
              }
            }
            body {
              richText
            }
            link {
              label
              path
            }
          }
        }
        cta1 {
          text {
            heading
            description
          }
          link {
            label
            path
          }
        }
        testimonials {
          title
          showTestimonials
          anchorText
          testimonials {
            media {
              url
              alternativeText
              mime
              ext
            }
            quote
            description
            name
            position
            company
            logo {
              url
              alternativeText
              mime
              ext
            }
          }
        }
        articles {
          sup
          heading
          relatedCard {
            case_study {
              ...CardArticleCaseStudy
            }
            blog {
              ...CardArticleBlog
            }
          }
        }
        casesAndServices {
          sup
          heading
        }
        cta2 {
          text {
            heading
            description
          }
          link {
            label
            path
          }
          anchorText
        }
        serviceEmail
        seo {
          metaData {
            metaTitle
            metaDescription
            keywords
          }
        }
        copyright
      }
    }
    thumbrow: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 239, height: 239, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
    category: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 655, height: 369, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
    logo: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 155, height: 105, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
  }
`

export default ServiceDetailUx;
